$(document).ready(function() {
  var myModalEl = document.getElementById('myModal')
  $(document).on('show.bs.modal','#myModal', function (event) {
    // Button that triggered the modal
    var button = event.relatedTarget
    // Extract info from data-bs-* attributes
    var cabinetID = button.getAttribute('data-cabinet-id')
    // If necessary, you could initiate an AJAX request here
    // and then do the updating in a callback.
    //
    // Update the modal's content.
    var modalTitle = myModalEl.querySelector('.modal-title')
    modalTitle.textContent = 'Cabinet ID # ' + cabinetID

    var modalBody = document.querySelector('.modal-body')

    $.ajax({url: Routes.show_cabinet_details_path( cabinetID ), success: function(result){
        //alert("success"+result);
          $("#myModal .modal-body").html(result);
        $("#myModal").modal('show'); 

    }});
    
  })

});
